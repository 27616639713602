import ManageSessionsView from "views/ManageSessions";

import AllSessionsRoute from "./AllSessions";
import PastSessionsRoute from "./PastSessions";
import LiveAndUpcomingSessionsRoute from "./LiveAndUpcomingSessions";
import Spark from "services/Spark";

const loader = async ({ request }) => {
  const { data: schools } = await Spark.getIrpSchools({
    signal: request.signal,
  });

  return { schools };
};

export default {
  element: <ManageSessionsView />,
  path: "/manage_sessions",
  children: [AllSessionsRoute, LiveAndUpcomingSessionsRoute, PastSessionsRoute],
  id: "manage-sessions",
  loader,
};
