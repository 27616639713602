import { Tabs } from "@Ignite-Reading/ui-kit/components";
import SessionSearchInput from "components/SessionSearchInput";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useLocation,
  useNavigation,
  useSearchParams,
} from "react-router-dom";

import {
  SEARCH_INPUT_DEBOUNCE_DELAY,
  SEARCH_TERM_SEARCH_PARAM,
} from "constants";
import FilterBySchools from "components/FilterBySchools";
import { PAGE_SEARCH_PARAM, SCHOOL_FILTER_SEARCH_PARAM } from "constants";

const ManageSessionsView = () => {
  const location = useLocation();
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isLiveAndUpcomingSessionsRoute =
    location.pathname === "/manage_sessions";
  const initialSearchTerm = searchParams.get(SEARCH_TERM_SEARCH_PARAM) || "";
  const schools = searchParams.getAll(SCHOOL_FILTER_SEARCH_PARAM) || "";
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isSearchingLiveSessions, setIsSearchingLiveSessions] = useState(false);
  const [isSearchingUpcomingSessions, setIsSearchingUpcomingSessions] =
    useState(false);
  const debounceRef = useRef(
    debounce(value => {
      setSearchParams(prev => {
        if (value === "") {
          prev.delete(SEARCH_TERM_SEARCH_PARAM);
        } else {
          prev.set(SEARCH_TERM_SEARCH_PARAM, value);
        }
        if (prev.has(PAGE_SEARCH_PARAM)) {
          prev.delete(PAGE_SEARCH_PARAM);
        }

        return prev;
      });
    }, SEARCH_INPUT_DEBOUNCE_DELAY)
  );
  const onChangeSearchInputValue = useCallback(
    event => {
      if (isLiveAndUpcomingSessionsRoute) {
        setIsSearchingLiveSessions(true);
        setIsSearchingUpcomingSessions(true);
      }
      setSearchTerm(event.target.value);
      debounceRef.current(event.target.value);
    },
    [isLiveAndUpcomingSessionsRoute]
  );
  const search = useMemo(
    () =>
      createSearchParams({
        ...(searchTerm ? { [SEARCH_TERM_SEARCH_PARAM]: searchTerm } : {}),
        ...(schools ? { [SCHOOL_FILTER_SEARCH_PARAM]: schools } : {}),
      }).toString(),
    [schools, searchTerm]
  );
  const isSearching = useMemo(() => {
    if (isLiveAndUpcomingSessionsRoute) {
      return isSearchingLiveSessions || isSearchingUpcomingSessions;
    } else {
      return (
        searchParams.has(SEARCH_TERM_SEARCH_PARAM) &&
        navigation.state === "loading"
      );
    }
  }, [
    isLiveAndUpcomingSessionsRoute,
    isSearchingLiveSessions,
    isSearchingUpcomingSessions,
    navigation.state,
    searchParams,
  ]);

  useEffect(() => {
    const debouncer = debounceRef.current;

    return () => {
      debouncer?.cancel();
    };
  }, []);

  return (
    <div className="max-w-7xl w-full grow mx-auto flex flex-col px-4 sm:px-6 md:px-8">
      <main className="flex-1 flex flex-col space-y-6">
        <div className="sm:flex sm:item-center sm:justify-between mt-12">
          <div className="space-y-1">
            <h2 className="text-xl md:text-2xl font-semibold text-zinc-900">
              Sessions
            </h2>
            <p className="mt-2 text-sm text-gray-700">
              Manage and monitor live, upcoming, and past sessions.
            </p>
          </div>
          <p className="flex items-center text-zinc-500 text-xs mr-5 pt-0.5 whitespace-nowrap">
            <em>All times are Eastern Time</em>
          </p>
        </div>
        <Tabs
          className="space-y-10"
          selected={location.pathname}>
          <Tabs.List>
            <Tabs.Tab
              slots={{ root: Link }}
              to={{
                pathname: "/manage_sessions",
                search,
              }}
              value="/manage_sessions">
              Live and Upcoming
            </Tabs.Tab>
            <Tabs.Tab
              slots={{ root: Link }}
              to={{
                pathname: "/manage_sessions/past",
                search,
              }}
              value="/manage_sessions/past">
              Past Sessions
            </Tabs.Tab>
            <Tabs.Tab
              slots={{ root: Link }}
              to={{
                pathname: "/manage_sessions/all",
                search,
              }}
              value="/manage_sessions/all">
              All Sessions
            </Tabs.Tab>
            <div className="flex items-center gap-2">
              <div data-testid="filter-by-school">
                <FilterBySchools />
              </div>
              <SessionSearchInput
                onChange={onChangeSearchInputValue}
                isSearching={isSearching}
                value={searchTerm}
              />
            </div>
          </Tabs.List>
        </Tabs>
        <Outlet
          context={{
            isSearchingLiveSessions,
            isSearchingUpcomingSessions,
            setIsSearchingLiveSessions,
            setIsSearchingUpcomingSessions,
          }}
        />
      </main>
    </div>
  );
};

export default ManageSessionsView;
