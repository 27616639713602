import axios from "axios";

import { BASE_10 } from "constants";
import cleanUrl from "./cleanUrl";
import parseErrorMessage from "./parseErrorMessage";

const DEFAULT_CONFIG = {
  baseUrl: "/",
  namespace: "/",
  withCredentials: true,
  preflightHeaders: undefined,
  defaultHeaders: {},
  authClient: {
    getToken: () => null,
  },
};

export default class Api {
  constructor(opts = {}) {
    // Configuration settings for the service instance
    this.config = Object.assign(DEFAULT_CONFIG, opts);
    // Make axios send cookies withCredentials=true
    this.ajax = axios.create({
      baseURL: this.baseURL,
      withCredentials: this.config.withCredentials,
    });
    // Set content type to json
    this.ajax.defaults.headers.common["Content-Type"] = "application/json";

    try {
      // Custom Default Headers
      for (let header in this.config.defaultHeaders) {
        this.ajax.defaults.headers.common[header] =
          this.config.defaultHeaders[header];
      }
    } catch (_) {
      return false;
    }
  }

  set token(token) {
    if (token) {
      this.ajax.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete this.ajax.defaults.headers.common["Authorization"];
    }
  }

  get headers() {
    return this.ajax.defaults.headers.common;
  }

  set headers(headers) {
    for (let header in headers) {
      this.ajax.defaults.headers.common[header] = headers[header];
    }
  }

  get baseURL() {
    return cleanUrl(`${this.config.baseUrl}/${this.config.namespace ?? ""}`);
  }

  async fetch(
    { body, headers = {}, method = "get", params = {}, signal, url },
    preflightOptions
  ) {
    const response = {
      data: null,
      error: false,
      errorMessage: undefined,
      status: undefined,
      success: false,
    };

    let preflightHeaders = {};

    if (typeof this.config.preflightHeaders === "function") {
      preflightHeaders = this.config.preflightHeaders(preflightOptions);
    }

    headers = Object.assign(headers, preflightHeaders);

    try {
      this.token = await this.config.authClient.getToken();
      const res = await this.ajax({
        data: body,
        headers,
        method,
        params,
        signal,
        url,
      });
      const count = parseInt(res.headers.get("total-count"), BASE_10);
      const items = parseInt(res.headers.get("page-items"), BASE_10);
      const pages = parseInt(res.headers.get("total-pages"), BASE_10);

      response.data = res.data;
      response.pagination = {
        count: isNaN(count) ? undefined : count,
        items: isNaN(items) ? undefined : items,
        pages: isNaN(pages) ? undefined : pages,
      };
      response.headers = res.headers;
      response.lastUpdated = new Date();
      response.status = res.status;
      response.success = true;

      return response;
    } catch (error) {
      // Ignore errors from deliberate signal canceling
      if (error.name !== "CanceledError") {
        response.data =
          (typeof error?.response?.data !== "string" &&
            error?.response?.data) ||
          {};
        response.error = true;
        response.errorMessage = parseErrorMessage(error);
        response.status = error?.response?.status || error.code;
      }

      return response;
    }
  }
}
