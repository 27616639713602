import Api from "services/Api/Class";
import { oneWeekAgoFromTodayISO, oneWeekFromTodayISO, todayISO } from "utils";

const LIVE_SESSIONS_PER_PAGE = 8;
const UPCOMING_SESSIONS_PER_PAGE = 8;

export default class SessionService extends Api {
  constructor(opts) {
    super(opts);
    this.authClient = opts.authClient;
  }

  getSession({ signal, uuid }) {
    return this.fetch({
      url: "/sessions",
      params: { uuid },
      signal,
    });
  }

  getSessions({
    endDate,
    page = 1,
    schools = [],
    searchParam = null,
    signal,
    startDate,
  }) {
    return this.fetch({
      url: "/sessions/paginated",
      params: {
        end_date: endDate || oneWeekFromTodayISO(),
        page,
        schools,
        search_param: searchParam,
        start_date: startDate || todayISO(),
      },
      signal,
    });
  }

  getUpcomingSessions({ page = 1, schools = [], searchParam = null, signal }) {
    return this.fetch({
      url: "/sessions/upcoming",
      params: {
        items: UPCOMING_SESSIONS_PER_PAGE,
        page,
        schools,
        search_param: searchParam,
      },
      signal,
    });
  }

  getLiveSessions({ page = 1, schools = [], searchParam = null, signal }) {
    return this.fetch({
      url: "/sessions/live",
      params: {
        items: LIVE_SESSIONS_PER_PAGE,
        page,
        schools,
        search_param: searchParam,
      },
      signal,
    });
  }

  getPastSessions({
    endDate,
    page = 1,
    schools = [],
    searchParam = null,
    signal,
    startDate,
  }) {
    return this.fetch({
      url: "/sessions/past",
      params: {
        end_date: endDate || todayISO(),
        page,
        schools,
        search_param: searchParam,
        start_date: startDate || oneWeekAgoFromTodayISO(),
      },
      signal,
    });
  }

  getLessonSpaceSessionPlayback({ sessionId, signal }) {
    return this.fetch({
      url: "/lesson_space_session_playbacks",
      params: { session_id: sessionId },
      signal,
    });
  }

  handleJoinSession(session_id, user_uuid) {
    return this.fetch({
      url: "/user_sessions",
      method: "POST",
      body: { session_id, user_uuid },
    });
  }
}
