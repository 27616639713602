import { Table } from "@Ignite-Reading/ui-kit/components";
import * as PropTypes from "prop-types";
import { Suspense, useCallback } from "react";
import { Await, useSearchParams } from "react-router-dom";

import { BASE_10, PAGE_SEARCH_PARAM } from "constants";

const AsyncTable = ({
  children,
  columns,
  noDataMessage,
  pageSearchKey = PAGE_SEARCH_PARAM,
  paginationAriaLabel,
  resolve,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get(pageSearchKey) ?? 1, BASE_10);
  const onChangePage = useCallback(
    page => {
      setSearchParams(prevParams => {
        prevParams.set(PAGE_SEARCH_PARAM, page);

        return prevParams;
      });
    },
    [setSearchParams]
  );

  return (
    <Suspense fallback={<Table columns={columns} />}>
      <Await
        resolve={resolve}
        errorElement={
          <Table
            columns={columns}
            errorMessage="Something went wrong. Please try again later."
            hasError
          />
        }>
        {({ data, pagination }) => (
          <Table
            columns={columns}
            data={data}
            errorMessageClassName="static translate-x-0 sm:translate-x-0 left-auto sm:left-auto w-full"
            noDataMessage={noDataMessage}
            noDataMessageClassName="static translate-x-0 sm:translate-x-0 left-auto sm:left-auto w-full"
            pagination={{
              "aria-label": paginationAriaLabel,
              count: pagination.pages,
              onChange: onChangePage,
              page: currentPage,
            }}>
            {data => data.map(row => children(row))}
          </Table>
        )}
      </Await>
    </Suspense>
  );
};

AsyncTable.propTypes = {
  children: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ).isRequired,
  noDataMessage: PropTypes.string.isRequired,
  pageSearchKey: PropTypes.string,
  paginationAriaLabel: PropTypes.string.isRequired,
  resolve: PropTypes.shape({
    catch: PropTypes.func.isRequired,
    then: PropTypes.func.isRequired,
  }).isRequired,
};

export default AsyncTable;
