import { defer } from "react-router-dom";

import PastSessionView from "views/ManageSessions/PastSessions";
import {
  BASE_10,
  END_DATE_SEARCH_PARAM,
  PAGE_SEARCH_PARAM,
  SCHOOL_FILTER_SEARCH_PARAM,
  SEARCH_TERM_SEARCH_PARAM,
  START_DATE_SEARCH_PARAM,
} from "constants";
import SessionService from "services/Session";

const loader = async ({ request }) => {
  const url = new URL(request.url);
  const page = parseInt(url.searchParams.get(PAGE_SEARCH_PARAM), BASE_10);
  const pastSessionsPromise = SessionService.getPastSessions({
    endDate: url.searchParams.get(END_DATE_SEARCH_PARAM),
    page: isNaN(page) ? 1 : page,
    schools: url.searchParams.getAll(SCHOOL_FILTER_SEARCH_PARAM),
    searchParam: url.searchParams.get(SEARCH_TERM_SEARCH_PARAM),
    signal: request.signal,
    startDate: url.searchParams.get(START_DATE_SEARCH_PARAM),
  });

  return defer({ pastSessionsPromise });
};

export default {
  element: <PastSessionView />,
  path: "past",
  loader,
};
