import { FilterBySchool } from "@Ignite-Reading/ui-kit/components";
import { useState } from "react";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";

import { SCHOOL_FILTER_SEARCH_PARAM } from "constants";

const FilterBySchools = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const loaderData = useRouteLoaderData("manage-sessions");
  const initialSchoolUuids =
    searchParams.getAll(SCHOOL_FILTER_SEARCH_PARAM) || [];
  const [schoolUuids, setSchoolUuids] = useState(initialSchoolUuids);
  const schools = loaderData.schools.map(({ name, school_system, uuid }) => {
    return [uuid, name, school_system.uuid];
  });
  const schoolSystems = loaderData.schools
    .map(({ school_system }) => {
      return [school_system.uuid, school_system.name];
    })
    .filter(([schoolSystemUuid], index, array) => {
      const currIndex = array.findIndex(([uuid]) => {
        return uuid === schoolSystemUuid;
      });

      return currIndex === index;
    });
  const onChange = values => {
    setSchoolUuids(values);
    setSearchParams(prevParams => {
      prevParams.delete(SCHOOL_FILTER_SEARCH_PARAM);
      values.forEach(value => {
        prevParams.append(SCHOOL_FILTER_SEARCH_PARAM, value);
      });

      return prevParams;
    });
  };

  return (
    <FilterBySchool
      onChange={onChange}
      schools={schools}
      schoolSystems={schoolSystems}
      value={schoolUuids}
    />
  );
};

export default FilterBySchools;
