import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import RootLayout from "layouts/Root";
import { useEffect } from "react";

export default function ErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    Sentry.captureException(error);
    console.error("Error sent to Sentry:", error);
  }, [error]);

  return (
    <RootLayout>
      <section>
        <h1 data-testid="error-status">{error.status}</h1>
        <h2 data-testid="error-message">{error.message}</h2>
        <p>Uh oh! Something happened!</p>
      </section>
    </RootLayout>
  );
}
