import { Table } from "@Ignite-Reading/ui-kit/components";
import { useLoaderData } from "react-router-dom";

import AsyncTable from "components/AsyncTable";
import FilterByDate from "components/FilterByDate";
import {
  generateStudentEditLink,
  generateTutorEditLink,
  oneWeekFromTodayISO,
  todayISO,
} from "utils";

const AllSessionsView = () => {
  const { allSessionsPromise } = useLoaderData();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold text-zinc-700 flex items-center">
          All Sessions
        </h3>
        <FilterByDate
          defaultEndDate={oneWeekFromTodayISO()}
          defaultStartDate={todayISO()}
        />
      </div>
      <AsyncTable
        columns={[
          { id: "student", label: "Student" },
          { id: "primary_tutor", label: "Tutor" },
          { id: "sub_tutor", label: "Sub Tutor" },
          { id: "school_system_name", label: "School System" },
          { id: "school_name", label: "School" },
          { id: "date", label: "Date" },
          { id: "start_time", label: "Time" },
          { id: "status", label: "Status" },
        ]}
        noDataMessage="There are no sessions to display."
        paginationAriaLabel="All sessions pagination"
        resolve={allSessionsPromise}>
        {row => (
          <Table.Row key={row.id}>
            <Table.Cell>
              <div data-testid="student-name">
                <a
                  className="font-semibold text-brand-500 hover:text-brand-700 hover:underline"
                  href={generateStudentEditLink(row.student_uuid)}
                  rel="noopener noreferrer"
                  target="_blank">
                  {row.student}
                </a>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div data-testid="tutor-name">
                {row.primary_tutor ? (
                  <a
                    className="font-semibold text-brand-500 hover:text-brand-700 hover:underline"
                    href={generateTutorEditLink(row.primary_tutor_uuid)}
                    rel="noopener noreferrer"
                    target="_blank">
                    {row.primary_tutor ?? "None"}
                  </a>
                ) : (
                  "None"
                )}
              </div>
            </Table.Cell>

            <Table.Cell>
              <div data-testid="sub-tutor">
                {row.sub_tutor_pending ? (
                  "Pending"
                ) : row.sub_tutor ? (
                  <a
                    className="font-semibold text-brand-500 hover:text-brand-700 hover:underline"
                    href={generateTutorEditLink(row.sub_tutor_uuid)}
                    rel="noopener noreferrer"
                    target="_blank">
                    {row.sub_tutor}
                  </a>
                ) : (
                  "None"
                )}
              </div>
            </Table.Cell>

            <Table.Cell>
              <div data-testid="school-system-name">
                {row.school_system_name}
              </div>
            </Table.Cell>

            <Table.Cell>
              <div data-testid="school-name">{row.school_name}</div>
            </Table.Cell>

            <Table.Cell>{row.date}</Table.Cell>
            <Table.Cell>{row.start_time}</Table.Cell>
            <Table.Cell>{row.status}</Table.Cell>
          </Table.Row>
        )}
      </AsyncTable>
    </div>
  );
};

export default AllSessionsView;
